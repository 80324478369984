import { commons } from "@/services/requests";
import { ICard } from "global-types/global.types";
import { useQuery } from "react-query";



export default function useGetBasketData() {
  const {
    data: basketData,
    isLoading: isLoadingBasketData,
    status,
  } = useQuery(["BASKET"], commons.getBasketData);

  return {
    basketData,
    isLoadingBasketData,
    status,
  };
}
