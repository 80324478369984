import axios, { AxiosError } from "axios";
import { baseURL } from "@/services/api";
import { parseCookies } from "nookies";

export const axiosConfig = {
  baseURL,
  timeout: 100000,
};

interface Header {
  [index: string]: string;
}

export const getAxiosConfig = () => {
  const { auth, locale_path } = parseCookies();

  const headers: Header = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "x-localization": locale_path === "" ? "en" : locale_path,
  };

  if (auth) {
    headers["authorization"] = "Bearer " + auth;
  }

  const instance = axios.create({
    ...axiosConfig,
    headers,
  });

  instance.interceptors.request.use(
    (config) => {
      return config;
    },
    (error) => Promise.reject(error)
  );

  instance.interceptors.response.use(
    async (response) => {
      return response;
    },
    (error: AxiosError) => {
      return Promise.reject(error);
    }
  );

  return instance;
};
