import { AddressParams } from "@/components/Ui/Tools/AddressModal/AddressModal";
import axios from "axios";
import ICityGeneric, {
  IAddress,
  IBrands,
  IBrandsGeneric,
  IBreadCrumbs,
  ICard,
  ICategory,
  ICategoryGeneric,
  ICity,
  IContact,
  ICredit,
  IFaq,
  IGenericAddress,
  IOrderFollow,
  IOrderFollowGeneric,
  IOrderItem,
  IPagination,
  IPostPaymentCashData,
  IPostPaymentData,
  IProduct,
  IProductDataGeneric,
  IProfile,
  IRegister,
  ISearch,
  ISettingsAll,
  ISlider,
} from "global-types/global.types";
import { parseCookies } from "nookies";
import { IWhishlist, IWhishlistGeneric } from "pages/favorite";
import { baseURL } from "./api";
import { getAxiosConfig } from "./axiosConfig";

export const commons = {
  getHeaders: async () => {
    const res = await axios.get<ICategory<ICategoryGeneric>>(
      baseURL + "/category"
    );

    return res.data;
  },
  getSliders: async () => {
    const res = await axios.get<ISlider>(baseURL + "/home/slider");

    return res.data;
  },
  getProducts: async (params: any) => {
    const { auth } = parseCookies();

    const res = await getAxiosConfig().get<
      IProduct<IProductDataGeneric<IBrandsGeneric>[], IPagination>
    >(baseURL + `/product${auth ? "/user" : ""}`, {
      params: params.queryKey[1],
    });

    return res.data;
  },
  getCities: async () => {
    const res = await axios.get<ICity<ICityGeneric[]>>(baseURL + "/city");

    return res.data;
  },
  getSettings: async () => {
    const res = await axios.get<ISettingsAll>(baseURL + "/setting");

    return res.data;
  },
  postAddress: async (params: AddressParams) => {
    const res = await getAxiosConfig().post<IAddress<IGenericAddress>>(
      baseURL + "/profile/addresses",
      params
    );

    return res.data;
  },
  putUpdateAddress: async (params: AddressParams, id: string) => {
    const res = await getAxiosConfig().put<IAddress<IGenericAddress>>(
      baseURL + `/profile/addresses/${id}`,
      params
    );

    return res.data;
  },
  getAddress: async () => {
    const res = await getAxiosConfig().get<IAddress<IGenericAddress[]>>(
      baseURL + "/profile/addresses"
    );

    return res.data;
  },
  postSelectAddress: async (id: string) => {
    const res = await getAxiosConfig().post(
      baseURL + `/profile/addresses/${id}`
    );

    return res.data;
  },
  postDeleteAddress: async (id: string) => {
    const res = await getAxiosConfig().delete(
      baseURL + `/profile/addresses/${id}`
    );

    return res.data;
  },
  getOrdersItem: async (id: number) => {
    const res = await getAxiosConfig().get<IOrderItem<IOrderFollowGeneric>>(
      baseURL + `/order/${id}/items`
    );

    return res.data;
  },
  postAddFavorite: async (id: number) => {
    const res = await getAxiosConfig().post(baseURL + `/wishlists/${id}`);

    return res.data;
  },
  postDeleteFavorite: async (id: number) => {
    const res = await getAxiosConfig().delete(baseURL + `/wishlists/${id}`);

    return res.data;
  },
  getBasketData: async () => {
    const { uuid } = parseCookies();

    const res = await getAxiosConfig().get<ICard>(baseURL + `/cart/n`, {
      params: {
        uuid,
      },
    });

    return res.data;
  },
  postAddCard: async (id: number, uuid: string) => {
    const res = await getAxiosConfig().post<ICard>(
      baseURL + `/cart/add/${id}`,
      {
        uuid: uuid,
      }
    );

    return res.data;
  },
  deleteCard: async (id: number) => {
    const { uuid } = parseCookies();
    const res = await getAxiosConfig().delete<ICard>(baseURL + `/cart/${id}`, {
      params: {
        uuid,
      },
    });

    return res.data;
  },
  postIncrementBasket: async (id: number) => {
    const { uuid } = parseCookies();

    const res = await getAxiosConfig().post<ICard>(
      baseURL + `/cart/qty/${id}`,
      {
        uuid,
      }
    );

    return res.data;
  },
  postDecrementBasket: async (id: number) => {
    const { uuid } = parseCookies();

    const res = await getAxiosConfig().delete<ICard>(
      baseURL + `/cart/qty/${id}`,
      {
        params: {
          uuid,
        },
      }
    );

    return res.data;
  },
  getBreadCrumbs: async (params: any) => {
    const id = params.queryKey[1];

    const res = await getAxiosConfig().get<IBreadCrumbs>(
      baseURL + `/category/breadcrumb/${id}`
    );

    return res.data;
  },
};

export const pages = {
  brands: async (params: any) => {
    const res = await axios.get<IBrands<IBrandsGeneric[]>>(baseURL + "/brand", {
      params: params.queryKey[1],
    });

    return res.data;
  },
  postOrderNoStock: async (params: {
    product_id: number;
    name: string;
    phone: string;
  }) => {
    const res = await axios.post(baseURL + `/product/order`, params);

    return res.data;
  },
  orderFollow: async (params: { id: string }) => {
    const res = await axios.get<IOrderFollow<IOrderFollowGeneric>>(
      baseURL + `/order/${params.id}`
    );

    return res.data;
  },
  postPaymentGuest: async (params: any) => {
    const res = await axios.post<any>(baseURL + `/order/guest${params}`);

    return res.data;
  },
  getSearch: async (params: any) => {
    const res = await getAxiosConfig().get<
      IProduct<IProductDataGeneric<IBrandsGeneric>[], IPagination>
    >(baseURL + `/product/search`, {
      params: params.queryKey[1],
    });

    return res.data;
  },
  getSearchCategory: async (params: { textQuery: string }) => {
    const res = await getAxiosConfig().get<ISearch>(
      baseURL + `/product/searchcat`,
      {
        params,
      }
    );
    return res.data;
  },
  getFaq: async (locale?: string) => {
    const res = await axios.get<IFaq>(baseURL + `/faqs`, {
      headers: {
        "x-localization": locale ? locale : "az",
      },
    });

    return res.data;
  },
  postCredit: async (props: ICredit) => {
    const res = await getAxiosConfig().post<ICredit>(
      baseURL + `/kredit`,
      props
    );

    return res.data;
  },
  postContact: async (props: IContact) => {
    const res = await getAxiosConfig().post<IContact>(
      baseURL + `/contact`,
      props
    );

    return res.data;
  },
};

export const user = {
  postLogin: async (params: {
    phone: string;
    password: string;
    remember_me: number;
    uuid: null | string;
  }) => {
    const res = await axios.post(baseURL + `/login`, params);

    return res.data;
  },
  postRegister: async (params: { phone: string }) => {
    const res = await axios.post(baseURL + `/register`, params);

    return res.data;
  },
  getProfile: async () => {
    const res = await getAxiosConfig().get<IProfile>(baseURL + `/profile`);

    return res.data;
  },
  postVerifyPhone: async (params: { phone: string; code: string }) => {
    const res = await axios.post(baseURL + `/verify`, params);

    return res.data;
  },
  postSetPassword: async (params: {
    phone: string;
    code: string;
    password: string;
    uuid: string | null;
  }) => {
    const res = await axios.put<IRegister>(baseURL + `/register`, params);

    return res.data;
  },
  putSettings: async (params: { fullName: string; email: string }) => {
    const res = await getAxiosConfig().put<IProfile>(
      baseURL + `/profile/settings`,
      params
    );

    return res.data;
  },
  putPassword: async (params: {
    current_password: string | null;
    password: string | null;
    password_confirmation: string | null;
  }) => {
    const res = await getAxiosConfig().put<IProfile>(
      baseURL + `/profile/password`,
      params
    );

    return res.data;
  },
  getLogout: async () => {
    const res = await getAxiosConfig().get<IProfile>(baseURL + `/logout`);

    return res.data;
  },
  getFavorite: async () => {
    const res = await getAxiosConfig().get<IWhishlist<IWhishlistGeneric[]>>(
      baseURL + `/wishlists`
    );

    return res.data;
  },
  getUserWishList: async () => {
    const res = await getAxiosConfig().get<IWhishlist<IWhishlistGeneric[]>>(
      baseURL + `/wishlists`
    );

    return res.data;
  },
  postForgotPassword: async (params: { phone: string }) => {
    const res = await axios.post<{
      code: number;
      phone: number;
    }>(baseURL + `/password/forgot`, params);

    return res.data;
  },
  postPayment: async (params: any) => {
    const res = await getAxiosConfig().post(baseURL + `/cart`, params);
    return res.data;
  },
};
