import translate from "@/components/Ui/Static/translation.json";

export const checkTitle = (
  slug: string[],
  item: any,
  count: number,
  forBreadCrumbs: React.MutableRefObject<any>
): {
  name: string[] | null;
  desc: string | null;
  forBreadCrumbs: string[];
} | null => {
  const _slug = slug[slug.length - 1];

  var name = null;
  var desc = null;

  forBreadCrumbs.current = [];

  while (count <= item.length) {
    item.map((_item: any) => {
      const filtered = item.filter((e: any) => e.slug === _slug);

      slug.map((sl) => {
        const findBreadCrumbs = item.filter((e: any) => e.slug === sl);

        if (findBreadCrumbs.length !== 0) {
          if (forBreadCrumbs.current.length === 0) {
            forBreadCrumbs.current = [
              ...forBreadCrumbs.current,
              findBreadCrumbs[0],
            ];
          } else
            forBreadCrumbs.current.forEach((e) => {
              if (e.slug !== findBreadCrumbs[0].slug) {
                forBreadCrumbs.current = [
                  ...forBreadCrumbs.current,
                  findBreadCrumbs[0],
                ];
              }
            });
        }
      });

      if (filtered.length !== 0) {
        name = filtered[0].name;
        desc = filtered[0].desc;
      }
      if (slug.length > 1) {
        item = _item.children;
      }
    });

    count++;

    return { name, desc, forBreadCrumbs: forBreadCrumbs.current };
  }

  return null;
};

export const getSubMenuItem = (subMenuItems: any, slug: string) => {
  if (subMenuItems) {
    for (let i = 0; i < subMenuItems.length; i++) {
      if (subMenuItems[i]?.slug === slug) {
        return subMenuItems[i];
      }

      const found = getSubMenuItem(subMenuItems[i]?.children, slug);

      if (found) return found;
    }
  }
};

export const findAllSlug = (
  data: any,
  slug: string[],
  breadCrumbs: any,
  defaultUrl: string,
  type: string,
  endUrl?: {
    slug: string;
    name: string;
  }
) => {
  if (data) {
    if (type === "category") {
      for (let i = 0; i < data.length; i++) {
        for (let count = 0; count < slug.length; count++) {
          if (data[i].slug === slug[count]) {
            if (data[i].children.length !== 0) {
              data[i].defaultUrl = "categories";
            } else {
              data[i].defaultUrl = defaultUrl;
            }

            if (endUrl) {
              breadCrumbs.push(data[i], {
                id: 1000000000,
                slug: endUrl.slug,
                name: endUrl.name,
              });
              data[i].defaultUrl = defaultUrl;
            } else {
              breadCrumbs.push(data[i]);
            }
          }
          findAllSlug(data[i].children, slug, breadCrumbs, defaultUrl, type);
        }
      }
    } else {
      for (let count = 0; count < slug.length; count++) {
        breadCrumbs.push({
          name: slug[count],
          defaultUrl: defaultUrl,
        });
      }
    }
  }

  if (type === "category") {
    return [...new Set(breadCrumbs)].sort(function (a: any, b: any) {
      return a.id - b.id || a.name.localeCompare(b.name);
    });
  } else {
    return breadCrumbs;
  }
};

export const t = (key: string, locale: string): string => {
  const result = translate[key][locale];

  return result;
};

export const removeTags = (str: string): string => {
  if (str === null || str === "") return "";
  else str = str.toString();
  return str.replace(/(<([^>]+)>)/gi, "");
};

export const upperCaseFirstLetter = (word: string): string => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};
