import { pages } from "@/services/requests";
import { useMutation } from "react-query";

export default function useSearchCategory() {
  const {
    data,
    mutate: mutateSearchCategory,
    isLoading,
  } = useMutation((params: { textQuery: string }) =>
    pages.getSearchCategory(params)
  );

  return { data, mutateSearchCategory, isLoading };
}
