import { create } from "zustand";
import { persist, StateStorage } from "zustand/middleware";
import { destroyCookie, parseCookies, setCookie } from "nookies";
import { AddressParams } from "@/components/Ui/Tools/AddressModal/AddressModal";

const cookiePersistStorage: StateStorage = {
  setItem: (name, value) => {
    setCookie(null, name, value, {
      maxAge: 30 * 24 * 60 * 360,
      path: "/",
    });
  },
  getItem: (name) => {
    const value = parseCookies();
    return value[name] ?? null;
  },
  removeItem: (name) => {
    destroyCookie(null, name);
  },
};

type IFilter = {
  brands: string[];
  price: string | null;
  rate: string | null;
  is_discount: string | null;
  is_new: string | null;
  per_page: string;
  setFilter: (params: {
    price?: string | null;
    rate?: string | null;
    is_discount?: string | null;
    is_new?: string | null;
    per_page?: string;
  }) => void;
  setBrands: (params: { brands: string[]; type?: string }) => void;
  destroyFilter: () => void;
};

export const useFilter = create<IFilter>((set) => ({
  brands: [],
  price: null,
  rate: null,
  is_discount: null,
  is_new: null,
  per_page: "30",
  setFilter: (params) =>
    set((state) => {
      return { ...state, ...params };
    }),
  setBrands: (params) =>
    set((state) => {
      if (params.type === "delete") {
        return {
          ...state,
          brands: params.brands,
        };
      }

      return {
        ...state,
        brands: [...state.brands, ...params.brands],
      };
    }),
  destroyFilter: () =>
    set({
      brands: [],
      price: null,
      rate: null,
      is_discount: null,
      is_new: null,
      per_page: "30",
    }),
}));

type ICount = {
  count: number;
  setCount: (val: number) => void;
  destroyCount: () => void;
};

export const useCount = create<ICount>((set) => ({
  count: 1,
  setCount: (params) =>
    set((state) => ({
      count: state.count + params,
    })),
  destroyCount: () =>
    set({
      count: 1,
    }),
}));

interface IAddressModal extends AddressParams {
  setParams: (params: any) => void;
  destroyAccountModal: () => void;
  payment_type: string;
  email: string;
  address: string;
  type: number;
  error: false;
}

export const useAddressModal = create<IAddressModal>((set) => ({
  fullName: "",
  title: "",
  city: "",
  detail: "",
  phone: "",
  email: "",
  address: "",
  payment_type: "online",
  type: 2,
  error: false,
  setParams: (params) =>
    set((state) => ({
      ...state,
      ...params,
    })),
  destroyAccountModal: () =>
    set({
      fullName: "",
      title: "",
      city: "",
      detail: "",
      phone: "",
      email: "",
      address: "",
      payment_type: "online",
      type: 2,
      error: false,
    }),
}));
