import { ICategory, ICategoryGeneric } from "global-types/global.types";
import Link from "next/link";

interface Props {
  data: ICategory<ICategoryGeneric[]>;
  locale: any;
}

export default function Nav({ data, locale }: Props) {
  const checkChildren = (
    item: ICategoryGeneric["children"],
    slug: string,
    _slug: string
  ) => {
    if (item?.length === 0) {
      return `/${locale}/product-list/${slug}`;
    } else {
      return `/${locale}/categories/${slug}`;
    }
  };

  return (
    <div className="nav-holder">
      <nav id="nav" className="hide-mobile non-touchscreen-desktop">
        <h2 className="show-mobile">Categorieën muziekinstrumenten</h2>
        <ul>
          {data?.data?.map((item, index) => (
            <li className="intent" key={index.toString() + item.name}>
              <Link
                href={`/${locale}/categories/${item.slug}`}
                className="prd-bl"
                title={item.name}
              >
                <span className="web-menu-node">{item.name}</span>
              </Link>
              <ul className="level_2">
                {item.children.map((_item, index) => (
                  <div
                    className="header-at-top column_width_1"
                    key={index.toString() + _item.name}
                  >
                    <h4>
                      <Link
                        className="sectiontitle"
                        href={checkChildren(
                          _item.children,
                          _item.slug,
                          _item.slug
                        )}
                        title={_item.name}
                      >
                        {_item.name}
                      </Link>
                    </h4>
                  </div>
                ))}
                <div className="menu-padding" />
              </ul>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
}
