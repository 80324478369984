import { Dispatch, SetStateAction, useEffect } from "react";

interface Props {
  options: Record<string, any>;
  elements: HTMLElement[] | null[];
  render: Array<any>;
  fn: Dispatch<SetStateAction<string | null | undefined>>;
}

export default function useShowCategoryResult({
  options,
  elements,
  render,
  fn,
}: Props) {
  function callback(entries: IntersectionObserverEntry[]) {
    const allView = entries.map((item) => item.isIntersecting);

    if (allView.length === elements.length) {
      fn(elements[0]?.getAttribute("data-type"));
    } else {
      entries.forEach((item) => {
        if (!item.isIntersecting) {
          fn(item.target.nextElementSibling?.getAttribute("data-type"));
        } else {
          fn(item.target?.getAttribute("data-type"));
        }
      });
    }
  }
  useEffect(() => {
    const cleanedArray: HTMLElement[] = [];

    elements.forEach((item: HTMLElement | null) => {
      if (item) {
        cleanedArray.push(item);
      }
    });

    if (cleanedArray.length > 0) {
      const observer = new IntersectionObserver(callback, options);
      cleanedArray.forEach((item, index) => {
        if (index === 0) {
          fn(item.getAttribute("data-type"));
        }

        observer.observe(item);
      });
    }
  }, [...render]);
}
