import { useRouter } from "next/router";
import { setCookie } from "nookies";

interface Props {
  locale: string;
}

const LangSelect = ({ locale }: Props) => {
  const { asPath } = useRouter();

  const langs = [
    { value: "az", label: "az" },
    { value: "en", label: "en" },
    { value: "ru", label: "ru" },
  ];

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.currentTarget;

    setCookie(null, "locale_path", value, {
      maxAge: 30 * 24 * 60 * 60,
      path: "/",
    });

    window.location.href = "/" + value + asPath;
  };

  return (
    <select
      onChange={handleChange}
      className="langSelect"
      defaultValue={locale}
    >
      {langs.map((item, index) => (
        <option key={index.toString() + item.label} value={item.value}>
          {item.label}
        </option>
      ))}
    </select>
  );
};
export default LangSelect;
