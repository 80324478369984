import React from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Nav from "../../Pages/Nav/Nav";
import TopBanner from "../../Pages/TopBanner/TopBanner";
import useHeader from "@/hooks/common/useHeader";
import { ICategory, ICategoryGeneric } from "global-types/global.types";

interface Props {
  children: React.ReactNode;
  locale: string;
  data: ICategory<ICategoryGeneric[]>;
}

export default function RootLayout({ data, children, locale }: Props) {
  return (
    <>
      <TopBanner locale={locale} />
      {data ? <Header locale={locale} data={data} /> : ""}
      {data ? <Nav data={data} locale={locale} /> : ""}
      {children}
      {data ? <Footer data={data} locale={locale} /> : ""}
    </>
  );
}
